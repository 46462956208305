<template>
  <div class="create-content-main">
    <div class="create-slag-form background-content-admin">
      <div class="row cpx-4">
        <div class="col col-auto">
          <div class="w-100 font-weight-bold header-font" v-if="!isEdit">
            URL登録
          </div>
          <div class="w-100 font-weight-bold header-font" v-else>URL 編集</div>
        </div>
        <div class="col col-auto">
          <div class="mx-3">
            <label
              v-for="(item, index) in listSetting"
              :key="index"
              class="mr-3 position-relative py-2"
              :class="
                typeSetting === item.value
                  ? 'btn-tab-sale-active'
                  : 'btn-tab-sale-inactive'
              "
              @click="typeSetting = item.value"
              style="font-weight: normal !important; font-size: 17px"
            >
              {{ item.text }}
            </label>
          </div>
        </div>
      </div>
      <form id="create-content" autocomplete="off">
        <div v-if="typeSetting === 1">
          <!-- <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">NO</label>
            <div class="cp-4 w-100">
              <input
                class="form-input m-0"
                type="text"
                disabled
                v-model="infoNew.no"
              />
            </div>
          </div> -->
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >短縮URL<span class="required">(必須)</span></label
            >
            <div class="w-100 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="infoNew.short_url_show"
                v-click-outside="checkShortUrl"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >元URL<span class="required">(必須)</span></label
            >
            <div class="w-100 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="infoNew.original_url"
              />
            </div>
          </div>
        </div>
        <div class="row row-input" v-if="typeSetting === 3">
          <label class="col-sm-12 font-weight-bold cp-label"
            >スラグ毎・リダイレクト設定（スラグ毎に設定）</label
          >
          <div
            class="col-sm-12 font-weight-bold cp-4 m-0"
            v-for="(value, index) in infoNew.setting_slag"
            :key="index"
          >
            <div class="algin-items-center row">
              <div class="d-flex align-items-center col-md-11 col-10">
                <div class="w-100">
                  <b-form-select
                    class="col-md-6 col-sm-12 custome-text-select mr-md-4 col-12"
                    id="select-auth"
                    v-model="value.slag_id"
                    :options="listSlagOptions"
                  ></b-form-select>
                  <input
                    class="col-md-5 col-sm-12 col-10 mr-sm-2 ml-sm-2 float-right mt-sm-2 mt-md-0"
                    type="text"
                    v-model="value.slag_url"
                  />
                </div>
              </div>
              <b-button
                variant="dark"
                class="rounded-circle custome-btn-sub float-right mt-0"
                style="border-radius: 50% !important"
                v-on:click="subSlag(index)"
                ><b-icon icon="dash" scale="2"
              /></b-button>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                variant="success"
                class="rounded-circle custome-btn-add"
                style="border-radius: 50% !important"
                v-on:click="addSlag(index)"
                ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
              /></b-button>
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 2">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >アクセス可能日時設定</label
            >
            <div
              class="fd-admin-listproperty-card-filter filter-date d-flex cp-4"
            >
              <date-picker
                name="date"
                v-model="infoNew.time_start"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select"
              ></date-picker>
              <span class="my-auto" style="font-size: 23px">~</span>
              <date-picker
                name="date"
                v-model="infoNew.time_end"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select"
              ></date-picker>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ログインなし設定
            </label>
            <div class="cp-4">
              <b-form-checkbox
                size="lg"
                v-model="infoNew.is_login"
                value="1"
                unchecked-value="0"
              >
                ページ閲覧にログインを必要とする
              </b-form-checkbox>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >スラグ設定</label
            >
            <div
              class="d-flex justify-content-between align-items-center add-slag col-sm-12 cp-4"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="infoNew.slags_id"
                  tag-placeholder="スラグを選択してください"
                  placeholder="スラグを選択してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagGrantOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >アクセス不可時・リダイレクトURL設定<span class="required"
                >(必須)</span
              ></label
            >
            <input
              class="form-input"
              type="text"
              v-model="infoNew.redirect_url"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            v-on:click.prevent="save()"
            :disabled="loadingSave"
            class="mr-4 btn-color sale-btn"
          >
            <b-spinner v-if="loadingSave" small></b-spinner>
            <span v-if="!isEdit">登録</span>
            <span v-else>更新する</span>
          </b-button>
          <b-button class="mr-4 back-btn" v-on:click="cancel()">
            戻る
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { FeedBack } from "@/utils/feedback.js";
import { Constants } from "../../utils/constants.js";
import vClickOutside from "v-click-outside";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "CreateURL",
  components: {},
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      feedback: FeedBack,
      extConstant: this.$route.params.shopId
        ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
        : "_" + Constants.DOMAIN,
      shop_id: null,
      listCategoryOptions: Constants.CATEGORY_FIXED_PAGE,
      listSlagOptions: [],
      listSlagGrantOptions: [],
      optionPublic: [
        { text: "非公開", value: 0 },
        { text: "公開中", value: 1 },
      ],
      infoNew: {
        no: localStorage.getItem(Constants.NO_URL),
        short_url: "",
        original_url: "",
        redirect_url: "",
        setting_slag: [{ slag_id: "", slag_url: "" }],
        time_start: "",
        time_end: "",
        slags_id: [],
        is_login: 0,
        to: 0,
        setting_condition: 0,
        setting_period: 1,
        setting_period_time: 1000,
        short_url_show: "",
      },
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      isEdit: false,
      loadingSave: false,
      check: true,
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "詳細設定" },
        { value: 3, text: "期間設定" },
      ],
      typeSetting: 1,
    };
  },
  created() {
    this.shop_id =
      localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant) &&
      JSON.parse(
        localStorage.getItem(Constants.ADMIN_USER_INFO + this.extConstant)
      ).shop_id;
    const request = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(request);
    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    if (reqData.id) {
      this.isEdit = true;
      this.getUrlById(reqData);
    }
  },
  computed: {
    ...mapGetters(["listSlag", "detailUrl", "success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    detailUrl() {
      this.infoNew.short_url = this.detailUrl.short_url;
      if (this.infoNew.short_url && this.infoNew.short_url.startsWith("/")) {
        this.infoNew.short_url_show = this.infoNew.short_url.substring(1);
      } else {
        this.infoNew.short_url_show = this.infoNew.short_url;
      }
      this.infoNew.original_url = this.detailUrl.original_url;
      this.infoNew.redirect_url = this.detailUrl.redirect_url;
      this.infoNew.setting_slag =
        this.detailUrl.setting_slag &&
        JSON.parse(this.detailUrl.setting_slag).length != 0
          ? JSON.parse(this.detailUrl.setting_slag)
          : [{ slag_id: "", slag_url: "" }];
      this.infoNew.time_start = this.detailUrl.time_start;
      this.infoNew.time_end = this.detailUrl.time_end;
      this.infoNew.slags_id = this.detailUrl.slags.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
      this.infoNew.is_login = this.detailUrl.is_login;
      this.infoNew.setting_period = this.detailUrl.setting_period;
      this.infoNew.time_start = this.detailUrl.setting_period_date_start;
      this.infoNew.time_end = this.detailUrl.setting_period_date_end;
    },
    listSlag() {
      if (this.listSlag.length > 0) {
        this.listSlagOptions = this.listSlag.map((item) => ({
          // text:
          //   item &&
          //   item.slag_name_en &&
          //   item.slag_name_en.length > Constants.MAX_LENGTH
          //     ? item.slag_name_en.substr(0, Constants.MAX_LENGTH) + "..."
          //     : item.slag_name_en,
          text: item.slag_name ? item.slag_name : item.slag_name_en,
          value: item.id.toString(),
        }));
      } else {
        this.listSlagOptions = [
          { text: "リストは空です", value: "", disabled: true },
        ];
      }
      this.listSlagGrantOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
  },
  methods: {
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getUrlById: "getUrlById" }),
    ...mapActions({ UpdateURL: "UpdateURL" }),
    ...mapActions({ CreateURL: "CreateURL" }),
    cancel() {
      this.$router.go(-1);
    },
    checkShortUrl() {
      if (
        this.infoNew.short_url_show &&
        this.infoNew.short_url_show.startsWith("/")
      ) {
        this.infoNew.short_url_show = this.infoNew.short_url_show.substring(1);
      }
    },
    addSlag(index) {
      this.infoNew.setting_slag.splice(index + 1, 0, {
        slag_id: "",
        slag_url: null,
      });
    },
    subSlag(index) {
      this.infoNew.setting_slag.splice(index, 1);
      if (this.infoNew.setting_slag && this.infoNew.setting_slag.length == 0) {
        this.infoNew.setting_slag.push({
          slag_id: "",
          slag_url: "",
        });
      }
    },
    async save() {
      this.loadingSave = true;
      this.check = true;
      let shop_id = this.shop_id;
      if (
        (this.infoNew.original_url &&
          (this.infoNew.original_url.startsWith("wwww") ||
            this.infoNew.original_url.startsWith("http")) &&
          !this.infoNew.original_url.match(Constants.REGEX_URL)) ||
        (this.infoNew.original_url &&
          !(
            this.infoNew.original_url.startsWith("wwww") ||
            this.infoNew.original_url.startsWith("http")
          ) &&
          !this.infoNew.original_url.startsWith("/"))
      ) {
        this.loadingSave = false;
        this.check = false;
        this.$toasted.error("元URL設定が正しい形式ではありません");
      } else if (
        this.infoNew.time_start &&
        this.infoNew.time_end &&
        this.infoNew.time_start > this.infoNew.time_end
      ) {
        this.loadingSave = false;
        this.check = false;
        this.$toasted.error("終了日には、開始日より後の日付を指定してください");
      } else if (
        this.infoNew.redirect_url &&
        !this.infoNew.redirect_url.match(Constants.REGEX_URL)
      ) {
        this.loadingSave = false;
        this.check = false;
        this.$toasted.error("リダイレクトURL設定が正しい形式ではありません");
      } else {
        this.infoNew.setting_slag.forEach((setting_slag, index) => {
          if (
            setting_slag.slag_url &&
            !setting_slag.slag_url.match(Constants.REGEX_URL)
          ) {
            this.loadingSave = false;
            this.check = false;
            this.$toasted.error(
              "スラッグ名No" +
                (index + 1) +
                "のリダイレクトURLフィールドは正しい形式ではありません。"
            );
          }
        });
        if (this.check) {
          let slags = [];
          this.infoNew.slags_id.forEach((value) => {
            slags.push(value.slag_id);
          });
          if (
            this.infoNew.short_url_show &&
            !this.infoNew.short_url_show.startsWith("/")
          ) {
            this.infoNew.short_url = "/" + this.infoNew.short_url_show;
          } else {
            this.infoNew.short_url = this.infoNew.short_url_show;
          }
          const request = {
            shop_id: shop_id,
            id: this.$route.params.id ? this.$route.params.id : "",
            short_url: this.infoNew.short_url,
            original_url: this.infoNew.original_url,
            redirect_url: this.infoNew.redirect_url,
            setting_slag: this.infoNew.setting_slag,
            is_login: this.infoNew.is_login,
            to: this.infoNew.to,
            setting_condition: this.infoNew.setting_condition,
            setting_period: this.infoNew.setting_period,
            setting_period_time: this.infoNew.setting_period_time,
            setting_period_date_start: this.infoNew.time_start,
            setting_period_date_end: this.infoNew.time_end,
            slag_id: slags,
          };
          if (request.id) {
            const dataReturn = await this.$store.dispatch("UpdateURL", request);
            if (dataReturn.success) {
              this.$router.go(-1);
              localStorage.removeItem(Constants.NO_URL);
            } else {
              this.loadingSave = false;
            }
          } else {
            const dataReturn = await this.$store.dispatch("CreateURL", request);
            if (dataReturn.success) {
              this.$router.go(-1);
              localStorage.removeItem(Constants.NO_URL);
            } else {
              this.loadingSave = false;
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
  .ql-editor {
    background-color: white;
    height: auto;
  }
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.custome-width-50 {
  width: 50%;
}
.btn-tab-sale-active,
.btn-tab-sale-inactive {
  @media (max-width: 767px) {
    width: 80px;
  }
  @media (min-width: 1280px) {
    width: 130px;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: #ff644e;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 7px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 100px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 9px solid #ff644e;
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.back-btn {
  width: 120px !important;
  height: 42px !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
@media (min-width: 1024px) {
  .header-font {
    position: relative;
    bottom: -5px;
  }
}
</style>
